import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const GetInvolved = () => {
  return (
    <div>
      <Layout>
        <SEO title="Get Involved" />
        <PageStructure
          title="Get Involved"
          desc="TESTIG"
          mode={ABOUTUS}
          content={`
        <b style="font-family:'Volkhov';">Membership</b>
        <br/><br/>
        The Membership of the Association is open to all  serving Group A Officers of the Indian Revenue Service who are not members of any other service association of the Income Tax Department. The membership of the Association is automatically discontinued on a member’s ceasing to belong to the Indian Revenue Service.
        <br/><br/><br/>
        <b style="font-family:'Volkhov';">Become a member</b> 
        <br/><br/>
        Fill up Form for DDO, Submit online and take a printout for submitting it to the DDO.
        `}
        />
      </Layout>
    </div>
  )
}

export default GetInvolved
